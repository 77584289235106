/* item button */
._1BZ_S {
  display: flex;
  border: 1px solid #acacac;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 0 #0000,
    0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: opacity 0.15s, background-color 0.15s;
  border-radius: 0.1rem;
}

._1BZ_S:hover {
  opacity: 0.6;
}

._1BZ_S._3tHh2 {
  height: 3.5rem;
}

/* views */
._24ZRP {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.25rem;
  height: 100%;
  align-items: center;
  padding: 1px;
}

._mDoGg {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.25rem;
  height: 100%;
  align-items: center;
  padding: 1px;
}

._vqHRN {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 0.25rem;
  height: 13rem;
  text-align: center;
  padding: 1px;
}

._2O9sG {
  padding-top: 0.4rem;
  font-size: 0.875rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #505050;
}

/* calendar*/
._Xg5X- {
  width: 15rem;
}

._2oyQ7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.25rem;
}

._2xL52 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

._SSZ80 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  min-width: 24px;
  color: #505050;
  background-color: white;
  border: 1px solid #909090;
  transition: opacity 0.15s;
  cursor: pointer;
  border-radius: 0.15rem;
}

._SSZ80._2RWmX {
  opacity: 0.3;
  cursor: not-allowed;
}

._SSZ80:hover {
  opacity: 0.6;
}

._qtKCN {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0 0.25rem;
}

._1Kp6O {
  transition: background-color 0.15s;
  padding: 0.1rem 0.25rem;
}

._1Kp6O._SXvCV {
  cursor: pointer;
}

._1Kp6O._SXvCV:hover {
  background-color: #ececec;
}

/* date picker */
._2U4s1 {
  background-color: white;
  padding: 0.4rem;
  border: 1px solid #909090;
}
